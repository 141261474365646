@media only screen and ( max-width: 991px ) {

	nav#nav {
		padding: 16px 0;
		.container {
			width: 100%;
			max-width: 100%;
			.row {
				width: 100%;
				.navbar-collapse {
					width: 100%;
					position: absolute;
					top: 50px;
					left: 0;
					z-index: 1000;
					background: $white;
				}
				.navbar-brand {
					padding: 0;
					img {
						height: 33px;
					}
				}
				&:first-child {
					padding: 0;
				}
			}
		}
		#search-row {
			height: 0;
			overflow: hidden;
			transition: all .3s ease-in-out;
			.col-12 {
				padding-top: 20px;
			}
			#search-form {
				padding: 0;
			}
			&.active {
				height: 60px;
			}
		}
	}

	#main-slider {
		.glide__slide {
			padding-bottom: 20px;
			.img-wrap {
				width: 100%;
				img {
					width: 100%;
				}
			}
			.slider-content {
				padding-top: 70px;
				position: relative;
				h1 {
					line-height: 48px;
					margin-bottom: 12px;
					font-size: 44px;
				}
				p {
					max-width: 100%;
					margin-bottom: 23px;
				}
			}
		}
		.glide__arrows {
			bottom: auto;
			right: 25px;
			.glide__arrow {
				width: 40px;
				height: 40px;
			}
		}
	}

	h2,
	h3 {
		font-size: 1.5rem;
	}

	h4 {
		font-size: 1.125rem;
	}

	.glide .glide__arrows .glide__arrow,
	.glide__arrow {
		width: 32px;
		min-width: 32px;
		height: 32px;
		img {
			width: 100%;
		}
	}

	.section-blocks {
		max-width: 140px;
		width: auto;
		height: auto;
		padding: 8px 25px;
		position: static;
		&:before {
			width: 110px;
			height: 110px;
		}
		h3 {
			font-size: 14px;
		}
		& + div {
			p {
				max-height: 48px;
				overflow: hidden;
			}
		}
	}

}

@media only screen and ( max-width: map-get($grid-breakpoints, md) ) {

	

}

@media only screen and ( max-width: 767px ) {

	#footer {
		padding-top: 60px;
		.follow-wrapper {
			margin-bottom: 30px;
			h6 {
				font-size: 18px;
			}
		}
		#newsletter-form {
			.form-head {
				font-size: 14px;
			}
			.form-group {
				& > label {
					font-size: 14px;
				}
				.captcha-wrapper {
					margin-right: 0;
				}
			}
		}
		.footer-bottom {
			p {
				line-height: 18px;
				font-size: 14px;
			}
			.footer-links {
				margin-bottom: 20px;
				a {
					line-height: 40px;
					font-size: 14px;
					padding: 0;
					border: none;
				}
			}
			.kvkk-links a {
				line-height: 40px;
				font-size: 14px;
			}
		}
	}

}

@media only screen and ( max-width: map-get($grid-breakpoints, sm) ) {


	
}