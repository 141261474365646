@media only screen and ( min-width: 1600px ) {

	body {

		// container with slider fix
		#home-events,
		#academy,
		#fellows,
		#home-testimonials,
		#testimonials,
		#home-blog,
		#early-events,
		#event-testimonials {
			.glide .glide__track {
				overflow: hidden!important;
			}
		}

		// container-fluid fix
		#home-academy,
		#academy,
		#home-fellowship,
		#fellows,
		#announcements,
		#partners,
		#academy-title-bar,
		#early-events-title,
		#gallery,
		#blog-title-bar,
		#contact {
			.container-fluid {
				max-width: 1540px;
			}
		}

		#home-academy,
		#academy,
		#home-fellowship,
		#fellows,
		#announcements,
		#partners,
		#academy-title-bar,
		#early-events-title,
		#gallery,
		#blog-title-bar,
		#contact {
			.container-fluid  > .row > div {
				padding: 0 24px;
			}
		}

		// Head
		#head,
		#contact {
			.head-img-wrapper {
				max-width: 1540px;
				padding: 0 24px;
				margin: 0 auto;
			}
			.col-1.col-xxxl-2 {
				display: none!important;
			}
		}

		// General
		#partners {
			background: transparent;
			.partner-wrapper {
				position: relative;
				&:before {
					width: calc(100% - 48px);
					height: 100%;
					content: "";
					display: block;
					position: absolute;
					top: 0;
					left: 24px;
					z-index: 0;
					background: $primary;
				}
				& > *:not(.section-blocks) {
					position: relative;
				}
				h6 {
					margin-left: 200px;
				}
				.section-blocks {
					left: 24px;
				}
			}
		}

		#gallery {
			.col-10 {
				flex: 0 0 100%!important;
				max-width: 100%!important;
				overflow: hidden;
				.glide {
					overflow: hidden;
				}
				.glide__track {
					width: 50%;
					margin: 0 auto;
				}
			}
		}

	}

}