// Required
@import "node_modules/bootstrap/scss/functions";
@import "./modules/fonts";
@import "./variables";
@import "node_modules/bootstrap/scss/mixins";

// Components & Utils
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/badge";
@import "node_modules/bootstrap/scss/breadcrumb";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/carousel";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/input-group";
@import "node_modules/bootstrap/scss/jumbotron";
@import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/media";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/popover";
@import "node_modules/bootstrap/scss/print";
@import "node_modules/bootstrap/scss/progress";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/spinners";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/toasts";
@import "node_modules/bootstrap/scss/tooltip";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/utilities";

// Environments
@import "./env";

// Modules
@import "./modules/animations";
@import "./modules/nav";
@import "./modules/main-head";
@import "./modules/rectangles";
@import "./modules/footer";
@import "./modules/max-width-fix";

html, body {
	width: 100%;
	height: 100%;
	min-width: 100%;
	min-height: 100%;
	position: relative;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.section-head {
	.animate.three-large-bottom {
		left: 0;
		bottom: 0;
	}
	h3 {
		max-width: 140px;
		line-height: 24px;
		font-size: 18px;
		font-weight: 500;
	}
}

.section-blocks {
	width: 200px;
	height: 200px;
	padding: 15px 55px 15px 15px;
	position: absolute;
	top: 0;
	left: 0;
	h3 {
		line-height: 24px;
		font-size: 18px;
		font-weight: 500;
		color: $white;
		position: relative;
		z-index: 10;
	}
	// &:before {
	// 	width: 160px;
	// 	height: 160px;
	// 	content: "";
	// 	display: block;
	// 	background: #990005;
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// }
	// &:after {
	// 	width: 40px;
	// 	height: 40px;
	// 	content: "";
	// 	display: block;
	// 	background: #990005;
	// 	position: absolute;
	// 	bottom: 0;
	// 	right: 0;
	// }
}

.img-wrapper {
	display: inline-block;
	position: relative;
}

.btn {
	min-width: 116px;
	line-height: 22px;
	padding: 9px 24px 7px 24px;
	font-weight: 500;
	white-space: nowrap;
	&.btn-outline-white {
		border-color: $white;
		color: $white;
		&:hover,
		&:focus {
			background-color: $white;
			color: $primary;
		}
	}
	&.btn-secondary {
		background: #E6E5E9;
		color: #5D5864;
		border: none;
		&:hover,
		&:active,
		&:focus {
			background: #DBDBDB!important;
			color: #5D5864!important;
		}
	}
	&.btn-primary.disabled,
	&.btn-primary:disabled, {
		background-color: #FA9696;
		border-color: #FA9696;
		opacity: 1;
	}
}

.btn-link {
	line-height: 24px;
	padding: 0;
	border: none;
	background: none;
	outline: none;
	font-size: 14px;
	font-weight: 500;
	color: #DC0005;
}

.btn-load-more {
	img {
		width: 20px;
		margin-right: 12px;
		pointer-events: none;
	}
	span {
		line-height: 36px;
		font-size: 16px;
		color: $primary;
		white-space: nowrap;
		pointer-events: none;
	}
}

#breadcrumb {
	border-bottom: 1px solid #F7F7F7;
	.custom-breadcrumb {
		padding: 15px 0;
		a, span {
			line-height: 20px;
			margin-right: 16px;
			font-size: 14px;
			color: #5D5864;
		}
		img {
			margin-right: 8px;
		}
	}
}

.form {
	.text-danger {
		line-height: 20px;
		padding-top: 4px;
	}
	.form-error {
		display: none;
	}
	&.error {
		.form-error {
			display: block;
		}
	}
	.form-group {
		.text-danger {
			display: none;
		}
		.custom-check + .text-danger {
			padding-left: 32px;
		}
		&.is-invalid {
			.text-danger {
				display: block;
			}
			.form-control {
				border: 1px solid $red!important;
			}
		}
	}
	.is-invalid.custom-check {
		& + .text-danger {
			display: block;
		}
	}
	.captcha-wrapper {
		&.is-invalid {
			.text-danger {
				display: block;
			}
		}
	}
}

.custom-check {
	label {
		padding-left: 32px;
		margin: 0;
		position: relative;
		cursor: pointer;
		&:before {
			width: 22px;
			height: 22px;
			content: "";
			display: block;
			border-radius: 100%;
			border: 2px solid #A6A1AD;
			position: absolute;
			top: 0;
			left: 0;
		}
		&:after {
			width: 14px;
			height: 14px;
			content: "";
			display: block;
			border-radius: 100%;
			background: #202020;
			position: absolute;
			top: 4px;
			left: 4px;
			opacity: 0;
			transition: all .3s ease-in-out;
		}
		span {
			line-height: 20px;
			font-size: 14px;
			color: #5D5864;
		}
	}
	input {
		display: none;
		&:checked + label {
			&:before {
				border-color: #202020;
			}
			&:after {
				opacity: 1;
			}
		}
	}
	input[type="checkbox"] + label {
		&:before {
			border-radius: 0;
		}
		&:after {
			width: 22px;
			height: 22px;
			top: 0;
			left: 0;
			border-radius: 0;
			background: #202020 url('../../img/icon-checkbox.svg') center center no-repeat;
		}
	}
	&.is-invalid {
		label {
			&:before {
				border-color: #DC0005;
			}
		}
	}
}

.file-input-wrapper {
	& > div {
		margin-bottom: 6px;
		border: 1px solid #E6E5E9;
		position: relative;
		label {
			line-height: 24px;
			padding: 11px 20px;
			margin: 0 15px 0 0;
			flex: 1;
			font-size: 14px;
			color: #202020;
			cursor: pointer;
		}
		.btn {
			margin-right: 20px;
			line-height: 24px;
			padding-top: 0;
			padding-bottom: 0;
		}
		.clear-file {
			width: 46px;
			height: 46px;
			display: block;
			opacity: 0;
			pointer-events: none;
			position: absolute;
			top: 0;
			right: 0;
			span {
				width: 20px;
				height: 1px;
				display: block;
				pointer-events: none;
				position: absolute;
				top: 21px;
				left: 13px;
				background: #202020;
				transform: rotateZ(45deg);
				&:last-child {
					transform: rotateZ(-45deg);
				}
			}
		}
	}
	.input-hint {
		line-height: 13px;
		font-size: 12px;
		font-weight: 500;
		color: #202020;
	}
	&.invalid {
		& > div {
			border-color: #DC0005;
		}
	}
	&.size-error {
		small.input-hint {
			color: #DC0005;
		}
	}
	&.filled {
		& > div {
			.clear-file {
				opacity: 1;
				pointer-events: initial;
			}
		}
	}
}

.glide {
	.glide__arrow {
		color: $black;
	}
}

.glide__arrow--disabled {
	opacity: 1!important;
}

.dropdown {
	.dropdown-toggle {
		text-decoration: none;
		&:after {
			display: none;
		}
		&[aria-expanded="true"] {
			& > img {
				transform: rotateX(180deg);
			}
		}
	}
	.dropdown-menu {
		min-width: auto;
		border: none;
		box-shadow: 0 1px 3px rgba(#000000, .16);
		a:active {
			background: #f8f9fa;
			color: #5D5864;
		}
	}
}

.form-info-modal {
	.modal-content {
		border: none;
		border-radius: 8px;
		box-shadow: 0px 15px 30px rgba($black, .08);
		.modal-body {
			padding: 36px 40px;
			.form-modal-content {
				img {
					margin-bottom: 40px;
				}
				h6 {
					line-height: 24px;
					margin-bottom: 12px;
					font-size: 18px;
					font-weight: 500;
					color: #5D5864;
				}
				p {
					line-height: 20px;
					margin-bottom: 32px;
					font-size: 14px;
					color: #5D5864;
				}
			}
		}
	}
}

.card {
	background: #FFFFFF;
	border: none;
	border-radius: 18px;
	box-shadow: 0px 15px 30px rgba(#000000, .08);
	.card-body {
		padding: 32px 20px;
	}
}

.form-modal {
	.modal-body {
		padding: 32px 20px;
		.modal-form-wrapper {
			h4 {
				line-height: 26px;
				margin-bottom: 12px;
				color: #5D5864;
			}
			p {
				line-height: 16px;
				padding: 0 10%;
				font-size: 12px;
				color: #5D5864
			}
			.form {
				width: 100%;
			}
		}
	}
}

.content-loader {
	width: 80px;
	height: 80px;
	margin: 40px auto;
	display: inline-block;
	position: relative;
	div {
		width: 64px;
		height: 64px;
		margin: 8px;
		box-sizing: border-box;
		display: block;
		position: absolute;
		border: 8px solid #DC0005;
		border-radius: 50%;
		animation: loader-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: #DC0005 transparent transparent transparent;
		&:nth-child(1) {
			animation-delay: -0.45s;
		}
		&:nth-child(2) {
			animation-delay: -0.3s;
		}
		&:nth-child(3) {
			animation-delay: -0.15s;
	}
	}
}

@keyframes loader-animation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@import "./global-media";