.blog-item {
	padding: 33px 40px;
	background: $white;
	box-shadow: 0 1px 3px rgba(#000000, .16);
	border-radius: 8px;
	color: $primary;
	transition: all .3s ease-in-out;
	.head {
		margin-bottom: 24px;
		span {
			line-height: 24px;
			font-size: 16px;
			&.date {
				font-size: 14px;
			}
		}
	}
	h4 {
		line-height: 32px;
		margin-bottom: 30px;
		font-weight: 500;
	}
	img {
		width: 100%;
		margin-bottom: 30px;
	}
	p {
		line-height: 24px;
		max-height: 48px;
		overflow: hidden;
		margin-bottom: 24px;
		font-size: 16px;
		color: #5D5864;
	}
	a.btn {
		transition: all .3s ease-in-out;
	}
	&:hover {
		background: $primary;
		box-shadow: none;
		// border-radius: 0;
		color: $white;
		p {
			color: $white;
		}
		a.btn {
			border-color: $white;
			color: $white;
			&:hover,
			&:focus {
				background-color: $white;
				color: $primary;
			}
		}
	}
}

@media only screen and ( max-width: map-get($grid-breakpoints, lg) ) {

	.blog-item {
		h4 {
			line-height: 20px;
			font-size: 16px;
		}
		.head {
			span {
				font-size: 14px;
			}
		}
	}

}

@media only screen and ( max-width: 767px ) {

	.blog-item {
		padding: 33px 17px;
	}

}