@font-face {
    font-family: 'campton';
    src: url('../../fonts/campton-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'campton';
    src: url('../../fonts/campton-semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'campton';
    src: url('../../fonts/campton-light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'campton';
    src: url('../../fonts/campton-book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'campton';
    src: url('../../fonts/campton-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

