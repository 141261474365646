@media only screen and ( min-width: 1600px ) {

	#fellowship-slider {
		.glide__arrow.glide__arrow--right {
			margin-right: 30px;
		}
	}

}

@media only screen and ( max-width: 1600px ) {

	#main-head {
		& > .container {
			max-width: 100%!important;
			padding: 0!important;
		}
	}

}

@media only screen and ( max-width: map-get($grid-breakpoints, lg) ) {

	#home-events {
		.head-row {
			margin-bottom: 20px;
			h2 {
				font-size: 2.125rem;
			}
			.btn-primary {
				min-width: 135px;
			}
		}
		#events-slider {
			.glide__track .glide__slide {
				.item .item-info {
					height: 50%;
					top: 50%;
					justify-content: flex-end;
				}
				&.small-slide {
					.item .item-info {
						height: 50%;
						top: 0;
						justify-content: flex-start;
					}	
				}
			}
			&:not(.glide--swipeable) {
				.glide__slide {
					width: 62.5%;
				}
			}
		}
	}

	#home-academy {
		h2 {
			font-size: 2.125rem;
		}
		#academy-slider {
			padding-left: 40px;
			.glide__slides {
				padding-bottom: 0;
			}
			.img-wrap {
				a.btn {
					padding: 4px 24px;
				}
			}
		}
	}

	#home-testimonials {
		padding: 60px 0 112px 0;
	}

	#home-testimonials {
		&:after {
			display: none;
		}
		.glide__track {
			overflow: hidden;
			.glide__slide {
				.item {
					text-align: center;
					.img-wrapper {
						width: 100%;
						.animate {
							&.t-l {
								top: 0;
								left: 0;
								.red {
									bottom: -40px;
								}
								.white {
									display: none;
								}
							}
						}
					}
					img {
						width: 100%;
					}
				}
			}
		}
		.desc-wrap {
			.desc {
				padding-top: 28px;
				text-align: center!important;
				span, p, img {
					display: flex;
				}
				span.name {
					margin-bottom: 6px;
					order: 1;
				}
				span.title {
					margin-bottom: 15px;
					order: 2;
				}
				img {
					max-height: 25px;
					margin-bottom: 15px;
					order: 3;
				}
				p {
					order: 4;
				}
			}
			.buttons {
				padding: 0;
			}
		}
	}

	[data-animated="true"] .glide__slide--active .animate.t-l.glide-anim:not(.not-resize).medium div:nth-child(1) {
		width: 40px;
		height: 40px;
	}

	#home-fellowship .fellowship-head h6 {
		line-height: 20px;
		margin-bottom: 8px;
		font-size: 18px;
	}

	#home-fellowship #fellowship-slider .arrow-container {
		padding-top: 30px;
		position: static;
	}

	#home-blog {
		h3 {
			font-size: 2.125rem;
		}
	}

	#partners {
		margin-bottom: 80px;
		.partner-wrapper h6 {
			line-height: 22px;
			font-size: 18px;
		}
	}

	#home-fellowship .fellowship-head {
		height: auto;
		.d-flex.flex-column.h-100 {
			height: auto!important;
		}
	}

	#partners {
		height: auto;
		.partner-wrapper {
			padding-left: 25px;
			padding-top: 10px;
			h6 {
				font-size: 14px;
				font-weight: 400;
				text-transform: uppercase;
			}
			#partner-slider {
				margin: 20px 0;
				width: 70%;
			}
		}
	}

}

@media only screen and ( max-width: map-get($grid-breakpoints, md) ) {

	#home-events #events-slider {
		.glide__track .glide__slide {
			.item {
				.item-info {
					width: 100%;
					padding: 15px;
					h6 {
						line-height: 18px;
						font-size: 14px;
					}
					ul {
						margin-top: 28px;
					}
				}
			}
			&.small-slide {
				padding: 0 40px;
				.item {
					.item-info {
						padding: 0;
					}
					ul {
						margin-top: 0;
					}
				}
			}
		}
		&:not(.glide--swipeable) {
			.glide__slide {
				width: 83.33%;
			}
		}
	}

	#home-academy #academy-slider {
		padding-left: 20px;
	}

	#home-blog #blog-slider .blog-item {
		h4 {
			height: 40px;
			max-width: inherit;
			white-space: inherit;
			text-overflow: inherit;
		}
	}

	
}

@media only screen and ( max-width: map-get($grid-breakpoints, sm) ) {


	
}