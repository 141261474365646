nav#nav {
	padding: 25px 0 24px 0;
	background: $white;
	border-top: 2px solid $red;
	border-bottom: 1px solid #F7F7F7;
	.container {
		flex-direction: column;
	}
	.row {
		width: calc(100% + 30px);
		&:first-child {
			padding-bottom: 29px;
		}
	}
	.dropdown-menu {
		min-width: auto;
		padding: 0;
		a {
			line-height: 18px;
			width: auto;
			height: auto;
			margin: 0;
			padding: 16px 20px;
			font-size: 14px;
			font-weight: 500;
			color: #5D5864;
		}
	}
	.navbar-toggler {
		width: 40px;
		height: 40px;
		padding: 0;
		position: relative;
		span {
			width: 25px;
			height: 2px;
			display: block;
			position: absolute;
			top: 11px;
			left: 6px;
			background: $black;
			transition: all .3s ease-in-out;
			&:nth-child(2) {
				width: 18.75px;
				top: 17px;
			}
			&:nth-child(3) {
				width: 12.5px;
				top: 23px;
			}
		}
		&[aria-expanded="true"] {
			span {
				&:nth-child(1) {
					top: 17px;
					transform: rotateZ(45deg);
				}
				&:nth-child(2) {
					left: 18px;
					opacity: 0;
				}
				&:nth-child(3) {
					width: 25px;
					transform: rotateZ(-45deg);
					top: 17px;
				}
			}
		}
	}
	.mobile-links {
		a {
			width: 40px;
			height: 40px;
			margin-right: 12px;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				max-height: 25px;
			}
			.profile-thumb {
				width: 32px;
				height: 32px;
				img {
					max-height: inherit;
				}
			}
		}
		.dropdown-menu {
			min-width: auto;
			padding: 0;
			a {
				line-height: 18px;
				width: auto;
				height: auto;
				margin: 0;
				padding: 16px 20px;
				font-size: 14px;
				font-weight: 500;
				color: #5D5864;
			}
		}
	}
	.navbar-nav {
		.nav-item {
			.nav-link {
				padding: .5rem 15px;
				font-size: 14px;
				font-weight: 500;
				color: #A5A1AC;
			}
			&:first-child {
				.nav-link {
					padding-left: 0;
				}
			}
			&:last-child {
				.nav-link {
					padding-right: 0;
				}
			}
			&.active {
				.nav-link {
					color: $black;
				}
			}
		}
	}
	#mobile-search-form,
	#search-form {
		padding-right: 60px;
		position: relative;
		.form-group {
			margin: 0;
			input {
				width: 100%;
				height: 40px;
				padding: 0 20px 0 66px;
				border: none;
				outline: none;
				border-radius: 20px;
				background: #F5F4F6;
				font-size: 18px;
				font-weight: 500;
				color: #A5A1AC;
				&::placeholder {
					font-size: 18px;
					font-weight: 500;
					color: #A5A1AC;
				}
			}
			img {
				position: absolute;
				top: 9px;
				left: 30px;
			}
		}
	}
	.sub-menu {
		.dropdown {
			margin-right: 14px;
			.dropdown-toggle {
				line-height: 24px;
				font-size: 18px;
				font-weight: 500;
				color: #202020;
			}
		}
	}
	.nav-mobile-menu {
		padding: 40px 15px 60px 15px;
		.mobile-form-wrapper {
			padding-bottom: 50px;
			form {
				width: 70%;
			}
			.dropdown {
				margin-right: 14px;
				.dropdown-toggle {
					line-height: 24px;
					font-size: 18px;
					font-weight: 500;
					color: #202020;
				}
			}
		}
		.mobile-accordion-menu {
			.accordion-item {
				margin-bottom: 20px;
				a {
					line-height: 32px;
					font-size: 18px;
					font-weight: 500;
					color: #5D5864;
					&.toggle {
						width: 32px;
						height: 32px;
						margin-left: 80px;
						img {
							width: 18px;
							transition: all .3s ease-in-out;
						}
						&[aria-expanded="true"] {
							img {
								transform: rotateX(180deg);
							}
						}
					}
				}
				.accordion-menu {
					display: none;
					& > div {
						padding: 20px 0 0 0;
					}
					a {
						line-height: 42px;
						font-size: 14px;
						font-weight: 300;
						color: $black;
					}
					&.collapsing {
						height: 0;
    					overflow: hidden;
    					display: block;
					}
					&.show {
						display: block;
					}
				}
				&:last-child {
					margin: 0;
				}
			}
		}
	}
	#userDropdown {
		.profile-thumb {
			width: 32px;
			height: 32px;
			display: block;
			border-radius: 100%;
			overflow: hidden;
			img {
				max-width: 100%;
				max-height: inherit;
			}
		}
	}
}