#main-head {
	margin-bottom: 120px;
	position: relative;
}

#slider-content-container {
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	.container,
	.row {
		height: 100%;
		min-height: 100%;
	}
	#main-slider-content {
		position: relative;
		z-index: 10;
		opacity: 0;
		transition: all .3s linear;
		transition-delay: 0;
		&.active {
			opacity: 1;
			transition-delay: .3s;
		}
		h1 {
			line-height: 72px;
			margin-bottom: 32px;
			font-size: 60px;
			font-weight: 500;
			color: $red;
		}
		p {
			max-width: 45%;
			margin-bottom: 24px;
			line-height: 24px;
			color: #5D5864;
		}
	}
}

#main-slider {
	.glide__slide {
		.img-wrap {
			width: 75%;
			position: relative;
			.rect {
				width: 0;
				height: 0;
				position: absolute;
				&.big {
					left: 0px;
					bottom: -80px;
					transition: all .3s ease-in-out;
					transition-delay: 1s;
				}
				&.medium.second {
					left: 80px;
					bottom: 0;
					transition: all .3s ease-in-out;
					transition-delay: 1.2s;
				}
				&.small.second {
					left: -20px;
					bottom: -100px;
					transition: all .3s ease-in-out;
					transition-delay: .8s;
				}
				&.small.third {
					left: -40px;
					bottom: -120px;
					transition: all .3s ease-in-out;
					transition-delay: .6s;
				}
			}
			picture, img {
				width: 100%;
			}
			picture {
				display: flex;
			}
			video {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 10;
			}
		}
		.slider-content {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			display: none!important;
			h1 {
				line-height: 72px;
				margin-bottom: 32px;
				font-size: 60px;
				font-weight: 500;
				color: $red;
			}
			p {
				max-width: 45%;
				margin-bottom: 24px;
				line-height: 24px;
				color: #5D5864;
			}
		}
		&.glide__slide--active {
			.img-wrap {
				.rect {
					&.big {
						width: 80px;
						height: 80px;
					}
					&.medium.second {
						width: 40px;
						height: 40px;
					}
					&.small.second {
						width: 20px;
						height: 20px;
					}
					&.small.third {
						width: 20px;
						height: 20px;
					}
				}
			}
		}
	}
	.glide__arrows {
		position: absolute;
		right: 15%;
		bottom: -80px;
		.glide__arrow--left {
			margin-right: 30px;
		}
	}
	&.glide--swipeable {
		.glide__track {
			height: calc(100% + 120px)!important;
		}
		.glide__track,
		.glide__slides,
		.glide__slide {
			height: 100%;
			min-height: 100%;
		}
		.glide__slides {
			width: 100%!important;
			transform: none!important;
			.glide__slide {
				width: 100%!important;
				margin: 0!important;
				position: relative;
				top: 0;
				right: 0;
				.img-wrap {
					right: -200vw;
					transition: all .6s linear;
					transition-delay: 0s;
				}
				&.glide__slide--active {
					.img-wrap {
						right: 0;
						transition-delay: .3s;
					}
				}
			}
		}
		&.size-fitted {
			.glide__slides .glide__slide {
				position: absolute;
				padding-bottom: 120px;
			}
		}
	}
}

@media only screen and ( max-width: 991px ) {

	#main-head {
		padding-top: 22px;
		margin-bottom: 0;
	}

	#slider-content-container {
		padding-top: 64px;
		padding-bottom: 30px;
		position: static;
		#main-slider-content {
			h1 {
				line-height: 48px;
				margin-bottom: 12px;
				font-size: 44px;
			}
			p {
				max-width: 100%;
			}
		}
	}

	#main-slider {
		.glide__track {
			height: calc(100% + 40px)!important;
		}
		.glide__slides,
		.glide__slide {
		}
		.glide__slide {
			.img-wrap .rect {
				&.big {
					left: 20px;
					bottom: -40px;
				}
				&.medium.second {
					left: 60px;
					bottom: 0;
				}
				&.small.second {
					left: 10px;
					bottom: -50px;
				}
				&.small.third {
					left: 0;
					bottom: -60px;
				}
			}
			&.glide__slide--active .img-wrap .rect {
				&.big {
					width: 40px;
					height: 40px;
				}
				&.medium.second {
					width: 20px;
					height: 20px;
				}
				&.small.second {
					width: 10px;
					height: 10px;
				}
				&.small.third {
					width: 10px;
					height: 10px;
				}
			}
		}
	}

}