#footer {
	padding: 78px 0 42px 0;
	background: #F5F5F8;
	.follow-wrapper {
		margin-bottom: 60px;
		padding-bottom: 45px;
		border-bottom: 4px solid $white;
		h6 {
			line-height: 32px;
			margin-bottom: 20px;
			font-size: 24px;
			font-weight: 500;
			color: #5D5864;
		}
	}
	#newsletter-form {
		padding-bottom: 18px;
		margin-bottom: 28px;
		border-bottom: 4px solid $white;
		.form-head {
			line-height: 24px;
			margin-bottom: 15px;
			font-size: 20px;
			font-weight: 500;
			color: #827F87;
		}
		.form-group {
			& > label {
				line-height: 30px;
				font-size: 16px;
				color: #707070;
			}
		}
		.form-control {
			border: none;
			&::placeholder {
				font-size: 16px;
				color: #707070;
				font-weight: 300;
			}
		}
		.custom-check {
			margin-top: 30px;
			span {
				color: #5D5864;
			}
		}
		.captcha-wrapper {
			margin-right: 22px;
		}
		.btn {
			line-height: 18px;
			padding: 6px 24px;
		}
	}
	.footer-bottom {
		p {
			line-height: 30px;
			margin-bottom: 60px;
			font-size: 16px;
			color: #707070;
			font-weight: 300;
		}
		.footer-links {
			margin-bottom: 50px;
			a {
				line-height: 50px;
				padding: 0 46px;
				font-size: 20px;
				font-weight: 500;
				color: #827F87;
				border-right: 1px solid #707070;
				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					padding-right: 0;
					border-right: 0;
				}
			}
		}
		.kvkk-links {
			a {
				line-height: 34px;
				font-size: 18px;
				font-weight: 500;
				color: rgba(#707070, .56);
			}
		}
	}
}