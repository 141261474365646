.rect {
	&.big {
		width: 80px;
		height: 80px;
	}
	.medium {
		width: 40px;
		height: 40px;
	}
	.small {
		width: 20px;
		height: 20px;
	}
	&.red {
		background: $red;
	}
	&.dark-red {
		background: #990005;
	}
	&.black {
		background: $black;
	}
}