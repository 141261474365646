ul {
	padding: 0;
	margin: 0 0 8px 0;
	display: flex;
	flex-direction: column;
	li {
		margin-bottom: 14px;
		.icon-wrap {
			width: 20px;
			text-align: center;
			margin-right: 16px;
			img {
				max-width: 100%;
			}
		}
		span, a {
			line-height: 24px;
			font-size: 16px;
			color: #5D5864;
		}
		span.label {
			width: 150px;
			font-size: 14px;
			color: #000000;
		}
		span.colon {
			margin: 0 14px;
			font-size: 14px;
			font-weight: 300;
		}
		span.value {
			font-size: 14px;
			color: #5D5864;
		}
	}
}

@media only screen and ( max-width: map-get($grid-breakpoints, lg) ) {

	ul {
		li {
			margin-bottom: 8px;
			.icon-wrap {
				margin-right: 8px;
				img {
					max-width: inherit;
					max-height: 15px;
					max-width: 15px;
				}
			}
			span, a {
				line-height: 18px;
				padding-top: 2px;
				font-size: 14px;
				font-weight: 500;
			}
			span.label {
				width: 126px;
			}
			span.colon {
				margin: 0 7px 0 4px;
			}
		}
	}

}