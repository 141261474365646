// Default direction top-right to bottom-left

.animate {
	position: absolute;
	z-index: 10;
	& > div {
		display: block;
		position: absolute;
		transition: all .3s ease-in-out;
	}
	&.large {
		div {
			&:nth-child(1) {
				width: 0;
				height: 0;
				bottom: 0;
				right: 0;
				transition-delay: .9s;
			}
			&:nth-child(2) {
				width: 0;
				height: 0;
				bottom: -160px;
				right: 160px;
				transition-delay: .6s;
			}
			&:nth-child(3) {
				width: 0;
				height: 0;
				bottom: -200px;
				right: 200px;
				transition-delay: .3s;
			}
		}
	}
	&.medium {
		div {
			&:nth-child(1) {
				width: 0;
				height: 0;
				bottom: -80px;
				left: -80px;
				transition-delay: .6s;
			}
			&:nth-child(2) {
				width: 0;
				height: 0;
				bottom: -120px;
				left: -120px;
				transition-delay: .3s;
			}
			&:nth-child(3) {
				width: 0;
				height: 0;
				bottom: -140px;
				left: -140px;
			}
		}
	}
	&.small {
		div {
			&:nth-child(1) {
				width: 0;
				height: 0;
				bottom: -40px;
				left: -40px;
				transition-delay: .6s;
			}
			&:nth-child(2) {
				width: 0;
				height: 0;
				bottom: -60px;
				left: -60px;
				transition-delay: .3s;
			}
			&:nth-child(3) {
				width: 0;
				height: 0;
				bottom: -70px;
				left: -70px;
			}
		}
	}
	&.three-bottom {
		left: 0;
		bottom: 0;
		top: auto;
		right: auto;
		div {
			&:nth-child(1) {
				width: 0;
				height: 0;
				right: -40px;
				bottom: -40px;
				transition-delay: .3s;
			}
			&:nth-child(2) {
				width: 0;
				height: 0;
				left: 40px;
				bottom: 0;	
				transition-delay: .6s;
			}
			&:nth-child(3) {
				width: 0;
				height: 0;
				right: -60px;
				bottom: -60px;	
			}
		}
	}
	&.three-top {
		left: auto;
		bottom: auto;
		top: 0;
		right: 0;
		div {
			&:nth-child(1) {
				width: 0;
				height: 0;
				left: -40px;
				bottom: -40px;
				transition-delay: .3s;
			}
			&:nth-child(2) {
				width: 0;
				height: 0;
				left: -80px;
				bottom: -80px;	
			}
			&:nth-child(3) {
				width: 0;
				height: 0;
				left: 0;
				bottom: -40px;	
				transition-delay: .4s;	
			}
		}
	}
	&.three-large-bottom {
		left: -80px;
		bottom: -80px;
		div {
			&:nth-child(1) {
				width: 0;
				height: 0;
				right: -80px;
				bottom: 0;
				transition-delay: .3s;
			}
			&:nth-child(2) {
				width: 0;
				height: 0;
				left: 80px;
				bottom: 80px;	
				transition-delay: .4s;
			}
			&:nth-child(3) {
				width: 0;
				height: 0;
				right: -100px;
				bottom: -20px;
			}
		}
	}
	&.three-async-first {
		right: 0;
		bottom: 0;
		div {
			&:nth-child(1) {
				width: 0;
				height: 0;
				left: -40px;
				bottom: 0;
				transition-delay: .5s;	
			}
			&:nth-child(2) {
				width: 0;
				height: 0;
				right: 40px;
				bottom: -40px;	
				transition-delay: .3s;
			}
			&:nth-child(3) {
				width: 0;
				height: 0;
				right: 20px;
				bottom: -60px;
			}
		}
	}
	&.three-async-second {
		left: 0;
		bottom: 0;
		div {
			&:nth-child(1) {
				width: 0;
				height: 0;
				left: 0;
				bottom: -40px;
			}
			&:nth-child(2) {
				width: 0;
				height: 0;
				left: 40px;
				bottom: 0;	
				transition-delay: .3s;
			}
			&:nth-child(3) {
				width: 0;
				height: 0;
				left: 80px;
				bottom: 0;
				transition-delay: .4s;	
			}
			&.l-60 {
				left: 60px;
			}
		}
	}
	&.three-sections {
		right: 0;
		bottom: 0;
		top: atuo;
		div {
			&:nth-child(1) {
				width: 0;
				height: 0;
				left: -80px;
				bottom: -80px;
				transition-delay: .5s;	
			}
			&:nth-child(2) {
				width: 0;
				height: 0;
				right: 80px;
				bottom: -100px;	
				transition-delay: .3s;
			}
			&:nth-child(3) {
				width: 0;
				height: 0;
				right: 40px;
				bottom: -140px;
			}
		}
	}
	.white {
		background: $white;
	}
	.black {
		background: $black;
	}
	.dark {
		background: #990005;
	}
	.red {
		background: #DC0005;
	}
	&.t-r {
		top: 0;
		right: 0;
	}
	&.t-l {
		top: 0;
		left: 0;
		&.large {
			div {
				&:nth-child(1) {
					bottom: -160px;
					left: auto;
					right: -160px;
				}
				&:nth-child(2) {
					bottom: -200px;
					left: auto;
					right: -200px;
				}
				&:nth-child(3) {
					bottom: -220px;
					left: auto;
					right: -220px;
				}
			}
		}
		&.medium {
			div {
				&:nth-child(1) {
					bottom: -80px;
					right: -80px;
					left: auto;
				}
				&:nth-child(2) {
					right: -120px;
					left: auto;
					bottom: -120px;
				}
				&:nth-child(3) {
					right: -140px;
					left: auto;
					bottom: -140px;
				}
			}
		}
		&.small {
			div {
				&:nth-child(1) {
					left: auto;
					right: -40px;
					bottom: -40px;
				}
				&:nth-child(2) {
					left: auto;
					right: -60px;
					bottom: -60;
				}
				&:nth-child(3) {
					left: auto;
					right: -70px;
					bottom: -70px;
				}
			}
		}
	}
	&.b-r {
		bottom: 0;
		right: 0;
		&.large {
			div {
				&:nth-child(1) {
					top: auto;
					right: 0;
					left: auto;
					bottom: 0;
					transition-delay: 0s;
				}
				&:nth-child(2) {
					top: auto;
					right: 160px;
					left: auto;
					bottom: 160px;
					transition-delay: .3s;
				}
				&:nth-child(3) {
					top: auto;
					right: 200px;
					left: auto;
					bottom: 200px;
					transition-delay: .6s;
				}
			}
		}
		&.medium {
			div {
				&:nth-child(1) {
					top: auto;
					bottom: 0;
					right: 0;
					transition-delay: 0s;
				}
				&:nth-child(2) {
					top: auto;
					bottom: 80px;
					right: 80px;
					transition-delay: .3s;
				}
				&:nth-child(3) {
					top: auto;
					bottom: 120px;
					right: 120px;
					transition-delay: .6s;
				}
			}
		}
		&.small {
			div {
				&:nth-child(1) {
					right: auto;
					left: 0;
					transition-delay: 0s;
				}
				&:nth-child(2) {
					right: auto;
					left: 40px;
					transition-delay: .3s;
				}
				&:nth-child(3) {
					right: auto;
					left: 60px;
					transition-delay: .6s;
				}
			}
		}
	}
	&.b-l {
		bottom: 0;
		left: 0;
		&.large {
			div {
				&:nth-child(1) {
					top: auto;
					left: 0;
					right: auto;
					bottom: 0;
				}
				&:nth-child(2) {
					top: auto;
					left: 160px;
					right: auto;
					bottom: 160px;
				}
				&:nth-child(3) {
					top: auto;
					left: 200px;
					right: auto;
					bottom: 200px;
				}
			}
		}
		&.medium {
			div {
				&:nth-child(1) {
					top: auto;
					bottom: 0;
					right: auto;
					left: 0;
					transition-delay: 0s;
				}
				&:nth-child(2) {
					top: auto;
					bottom: 80px;
					right: auto;
					left: 80px;
					transition-delay: .3s;
				}
				&:nth-child(3) {
					top: auto;
					bottom: 120px;
					right: auto;
					left: 120px;
					transition-delay: .6s;
				}
			}
		}
		&.small {
			div {
				&:nth-child(1) {
					right: auto;
					left: 0;
					bottom: 0;
					transition-delay: 0s;
				}
				&:nth-child(2) {
					right: auto;
					left: 40px;
					bottom: 40px;
					transition-delay: .3s;
				}
				&:nth-child(3) {
					right: auto;
					left: 60px;
					bottom: 60px;
					transition-delay: .6s;
				}
			}
		}
	}
}

[data-animated="true"] .animate:not(.glide-anim),
[data-animated="true"] .glide__slide--active .animate.glide-anim {
	&.large {
		div {
			&:nth-child(1) {
				width: 160px;
				height: 160px;
			}
			&:nth-child(2) {
				width: 40px;
				height: 40px;
			}
			&:nth-child(3) {
				width: 20px;
				height: 20px;
			}
		}
	}
	&.medium {
		div {
			&:nth-child(1) {
				width: 80px;
				height: 80px;
			}
			&:nth-child(2) {
				width: 40px;
				height: 40px;
			}
			&:nth-child(3) {
				width: 20px;
				height: 20px;
			}
		}
	}
	&.small {
		div {
			&:nth-child(1) {
				width: 40px;
				height: 40px;
			}
			&:nth-child(2) {
				width: 20px;
				height: 20px;
			}
			&:nth-child(3) {
				width: 10px;
				height: 10px;
			}
			&.s-20 {
				width: 20px;
				height: 20px;
			}
		}
	}	
	&.three-bottom,
	&.three-top {
		div {
			&:nth-child(1) {
				width: 40px;
				height: 40px;
			}
			&:nth-child(2) {
				width: 40px;
				height: 40px;
			}
			&:nth-child(3) {
				width: 20px;
				height: 20px;
				bottom: -60px;
			}
		}
	}
	&.three-top {
		div {
			&:nth-child(3) {
				right: -20px;
			}
		}
	}
	&.three-large-bottom {
		div {
			&:nth-child(1) {
				width: 80px;
				height: 80px;
			}
			&:nth-child(2) {
				width: 40px;
				height: 40px;
			}
			&:nth-child(3) {
				width: 20px;
				height: 20px;
			}
		}
	}
	&.three-async-first {
		div {
			&:nth-child(1) {
				width: 40px;
				height: 40px;
			}
			&:nth-child(2) {
				width: 40px;
				height: 40px;
			}
			&:nth-child(3) {
				width: 20px;
				height: 20px;
			}
		}
	}
	&.three-async-second {
		div {
			&:nth-child(1) {
				width: 40px;
				height: 40px;
			}
			&:nth-child(2) {
				width: 40px;
				height: 40px;
			}
			&:nth-child(3) {
				width: 20px;
				height: 20px;
				bottom: -20px;
			}
			&.s-20 {
				width: 20px;
				height: 20px;
			}
		}
	}
	&.three-sections {
		div {
			&:nth-child(1) {
				width: 80px;
				height: 80px;
			}
			&:nth-child(2) {
				width: 20px;
				height: 20px;
			}
			&:nth-child(3) {
				width: 40px;
				height: 40px;
			}
		}
	}
	&.b-r {
		&.medium {
			div {
				&:nth-child(1) {
					left: auto;
					right: 0;
				}
				&:nth-child(2) {
					left: auto;
					right: 80px;
				}
				&:nth-child(3) {
					left: auto;
					right: 120px;
				}
			}
		}
	}
}

[data-animated="true"] .glide__slide:not(.glide__slide--active) {
	.animate.glide-anim {
		&.large {
			div {
				&:nth-child(1) {
					width: 0;
					height: 0;
				}
				&:nth-child(2) {
					width: 0;
					height: 0;
				}
				&:nth-child(3) {
					width: 0;
					height: 0;
				}
			}
		}
		&.medium {
			div {
				&:nth-child(1) {
					width: 0;
					height: 0;
				}
				&:nth-child(2) {
					width: 0;
					height: 0;
				}
				&:nth-child(3) {
					width: 0;
					height: 0;
				}
			}
		}
		&.small {
			div {
				&:nth-child(1) {
					width: 0;
					height: 0;
				}
				&:nth-child(2) {
					width: 0;
					height: 0;
				}
				&:nth-child(3) {
					width: 0;
					height: 0;
				}
				&.s-20 {
					width: 0;
					height: 0;
				}
			}
		}
		&.three-bottom,
		&.three-top,
		&.three-large-bottom,
		&.three-async-first {
			div {
				&:nth-child(1) {
					width: 0;
					height: 0;
				}
				&:nth-child(2) {
					width: 0;
					height: 0;
				}
				&:nth-child(3) {
					width: 0;
					height: 0;
					bottom: 0;
				}
			}
		}
	}
}

@media only screen and ( max-width: 991px ) {

	.animate:not(.not-resize) {
		.sm-red {
			background: $red;
		}
		&.large {
			div {
				&:nth-child(1) {
					bottom: -110px;
					left: -110px;
				}
				&:nth-child(2) {
					bottom: -110px;
					left: -110px;
				}
				&:nth-child(3) {
					bottom: -130px;
					left: -130px;
				}
			}
		}
		&.medium {
			div {
				&:nth-child(1) {
					bottom: -20px;
					left: -20px;
				}
				&:nth-child(2) {
					bottom: -30px;
					left: -30px;
				}
				&:nth-child(3) {
					bottom: -40px;
					left: -40px;
				}
			}
		}
		&.small {
			div {
				&:nth-child(1) {
					bottom: -20px;
					left: -20px;
				}
				&:nth-child(2) {
					bottom: -30px;
					left: -30px;
				}
				&:nth-child(3) {
					bottom: -35px;
					left: -35px;
				}
			}
		}
		&.three-bottom {
			div {
				&:nth-child(1) {
					bottom: -40px;
				}
				&:nth-child(2) {
					left: 40px;
					bottom: 0;	
				}
				&:nth-child(3) {
					left: 40px;
					bottom: -40px;	
				}
			}
		}
		&.three-top {
			div {
				&:nth-child(1) {
					left: -20px;
					bottom: -20px;
				}
				&:nth-child(2) {
					left: -30px;
					bottom: -30px;	
				}
				&:nth-child(3) {
					left: 0;
					bottom: -20px;	
				}
			}
		}
		&.three-large-bottom {
			left: -40px;
			bottom: -40px;
			div {
				&:nth-child(1) {
					left: 0;
					bottom: 0;
				}
				&:nth-child(2) {
					left: 40px;
					bottom: 40px;	
				}
				&:nth-child(3) {
					left: 40px;
					bottom: 0;
				}
			}
		}
		&.three-async-first {
			right: 0;
			bottom: 0;
			div {
				&:nth-child(2) {
					right: 40px;
					bottom: -40px;	
				}
				&:nth-child(3) {
					right: 20px;
					bottom: -60px;
				}
			}
		}
		&.three-async-second {
			left: 0;
			bottom: 0;
			div {
				&:nth-child(1) {
					left: 0;
					bottom: -20px;
				}
				&:nth-child(2) {
					left: 20px;
					bottom: 0;	
				}
				&:nth-child(3) {
					left: 40px;
					bottom: 0;
				}
				&.l-60 {
					left: 40px;
				}
			}
		}
		&.three-sections {
			right: 0;
			bottom: 0;
			top: atuo;
			div {
				&:nth-child(1) {
					width: 0;
					height: 0;
					right: 0;
					top: 0px;
				}
				&:nth-child(2) {
					width: 0;
					height: 0;
					right: 40px;
					top: 40px;	
					transition-delay: .3s;
				}
				&:nth-child(3) {
					width: 0;
					height: 0;
					right: 40px;
					top: 60px;
					transition-delay: .5s;	
				}
			}
		}
		&.t-l {
			top: 0;
			left: 0;
			&.large {
				div {
					&:nth-child(1) {
						bottom: -110px;
						top: auto;
						left: auto;
						right: -110px;
					}
					&:nth-child(2) {
						bottom: -130px;
						top: auto;
						left: auto;
						right: -130px;
					}
					&:nth-child(3) {
						bottom: -140px;
						top: auto;
						left: auto;
						right: -140px;
					}
				}
			}
			&.medium {
				div {
					&:nth-child(1) {
						right: auto;
						left: 0;
						bottom: -40px;
					}
					&:nth-child(2) {
						right: auto;
						left: 20px;
					}
					&:nth-child(3) {
						right: auto;
						left: 30px;
					}
				}
			}
			&.small {
				div {
					&:nth-child(1) {
						right: auto;
						left: 0;
					}
					&:nth-child(2) {
						right: auto;
						left: 20px;
					}
					&:nth-child(3) {
						right: auto;
						left: 30px;
					}
				}
			}
		}
		&.b-r {
			bottom: 0;
			right: 0;
			&.large {
				div {
					&:nth-child(1) {
						top: auto;
						right: 0;
						left: auto;
						bottom: 0;
					}
					&:nth-child(2) {
						top: auto;
						right: 110px;
						left: auto;
						bottom: 110px;
					}
					&:nth-child(3) {
						top: auto;
						right: 130px;
						left: auto;
						bottom: 130px;
					}
				}
			}
			&.medium {
				div {
					&:nth-child(1) {
						top: auto;
						bottom: 0;
						right: 0;
					}
					&:nth-child(2) {
						top: auto;
						bottom: 20px;
						right: 20px;
					}
					&:nth-child(3) {
						top: auto;
						bottom: 30px;
						right: 30px;
					}
				}
			}
			&.small {
				div {
					&:nth-child(1) {
						right: auto;
						left: 0;
					}
					&:nth-child(2) {
						right: auto;
						left: 20px;
					}
					&:nth-child(3) {
						right: auto;
						left: 30px;
					}
				}
			}
		}
		&.b-l {
			bottom: 0;
			left: 0;
			&.large {
				div {
					&:nth-child(1) {
						top: auto;
						left: 0;
						right: auto;
						bottom: 0;
					}
					&:nth-child(2) {
						top: auto;
						left: 110px;
						right: auto;
						bottom: 110px;
					}
					&:nth-child(3) {
						top: auto;
						left: 130px;
						right: auto;
						bottom: 130px;
					}
				}
			}
			&.medium {
				div {
					&:nth-child(1) {
						top: auto;
						bottom: 0;
						right: auto;
						left: 0;
					}
					&:nth-child(2) {
						top: auto;
						bottom: 20px;
						right: auto;
						left: 20px;
					}
					&:nth-child(3) {
						top: auto;
						bottom: 30px;
						right: auto;
						left: 30px;
					}
				}
			}
			&.small {
				div {
					&:nth-child(1) {
						right: auto;
						left: 0;
						bottom: 0;
					}
					&:nth-child(2) {
						right: auto;
						left: 20px;
						bottom: 20px;
					}
					&:nth-child(3) {
						right: auto;
						left: 30px;
						bottom: 30px;
					}
				}
			}
		}
	}

	[data-animated="true"] .animate:not(.glide-anim):not(.not-resize),
	[data-animated="true"] .glide__slide--active .animate.glide-anim:not(.not-resize) {
		&.large {
			div {
				&:nth-child(1) {
					width: 110px;
					height: 110px;
				}
				&:nth-child(2) {
					width: 20px;
					height: 20px;
				}
				&:nth-child(3) {
					width: 10px;
					height: 10px;
				}
			}
		}
		&.medium {
			div {
				&:nth-child(1) {
					width: 20px;
					height: 20px;
				}
				&:nth-child(2) {
					width: 10px;
					height: 10px;
				}
				&:nth-child(3) {
					width: 10px;
					height: 10px;
				}
			}
		}
		&.small {
			div {
				&:nth-child(1) {
					width: 20px;
					height: 20px;
				}
				&:nth-child(2) {
					width: 10px;
					height: 10px;
				}
				&:nth-child(3) {
					width: 5px;
					height: 5px;
				}
				&.s-20 {
					width: 5px;
					height: 5px;
				}
			}
		}	
		&.three-bottom,
		&.three-top {
			div {
				&:nth-child(1) {
					width: 20px;
					height: 20px;
				}
				&:nth-child(2) {
					width: 10px;
					height: 10px;
				}
				&:nth-child(3) {
					width: 10px;
					height: 10px;
					bottom: -20px;
				}
			}
		}
		&.three-top {
			div {
				&:nth-child(3) {
					bottom: -30px;
					right: -10px;
				}
			}
		}
		&.three-large-bottom {
			div {
				&:nth-child(1) {
					width: 40px;
					height: 40px;
				}
				&:nth-child(2) {
					width: 20px;
					height: 20px;
				}
				&:nth-child(3) {
					width: 20px;
					height: 20px;
					bottom: -20px;
				}
			}
		}
		&.three-async-first {
			div {
				&:nth-child(1) {
					width: 40px;
					height: 40px;
				}
				&:nth-child(2) {
					width: 40px;
					height: 40px;
				}
				&:nth-child(3) {
					width: 20px;
					height: 20px;
				}
			}
		}
		&.three-async-second {
			div {
				&:nth-child(1) {
					width: 20px;
					height: 20px;
				}
				&:nth-child(2) {
					width: 20px;
					height: 20px;
				}
				&:nth-child(3) {
					width: 10px;
					height: 10px;
					bottom: -10px;
				}
				&.s-20 {
					width: 20px;
					height: 20px;
				}
			}
		}
		&.three-sections {
			div {
				&:nth-child(1) {
					width: 40px;
					height: 40px;
				}
				&:nth-child(2) {
					width: 20px;
					height: 20px;
				}
				&:nth-child(3) {
					width: 10px;
					height: 10px;
					right: 30px;
				}
			}
		}
	}

}