$env: "production";
// Global
@import "../../base/global";

// Glide JS
@import "../../base/modules/glide/glide-core";
@import "../../base/modules/glide/glide-theme";

// Other Modules
@import "../../base/modules/content-animation";

#home-events {
	max-width: 100%;
	padding: 56px 0 80px 0;
	overflow: hidden;
	.head-row {
		margin-bottom: 52px;
	}
	#events-slider {
		.glide__track,
		.glide__slides,
		.glide__slide {
			height: 100%;
			min-height: 100%;
		}
		.glide__track {
			overflow: visible;
			.glide__slides {
				align-items: stretch;
			}
			.glide__slide {
				transition: all .3s ease-in-out;
				.item {
					max-width: 100%;
					width: 100%;
					min-height: 100%;
					position: relative;
					.item-info {
						max-width: 80%;
						width: 80%;
						padding: 40px;
						position: absolute;
						left: 0;
						bottom: 0;
						z-index: 10;
						h6 {
							max-width: 100%;
							line-height: 24px;
							margin: 0;
							font-size: 18px;
							color: $white;
							font-weight: 500;
							&:last-child {
								display: none;
							}
						}
						@import "../../base/modules/icon-list";
						ul {
							margin: 36px 0 0 0;
							li {
								.icon-wrap {
									img:last-child {
										display: none;
									}
								}
								span {
									color: $white;
								}
							}
						}
					}
					.img-wrap {
						width: calc(100% - 1px);
						height: 100%;
						position: absolute;
						left: 0;
						bottom: 0;
						overflow: hidden;
						transition: all .3s ease-in-out;
						img {
							max-width: 100%;
							min-width: 100%;
							min-height: 100%;
							transition: all .5s ease-in-out;
						}
						&:before {
							width: 100%;
							height: 60%;
							display: block;
							content: "";
							position: absolute;
							left: 0;
							bottom: 0;
							z-index: 2;
							transition: all .3s ease;
							background: rgb(0,0,0);
							background: linear-gradient(0deg, rgba(0,0,0,0.80) 0%, rgba(0,0,0,0.70) 70%, rgba(0,0,0,0) 100%);
						}
					}
					&:hover {
						.img-wrap {
							img {
								transform: scale(1.05);
							}
						}
					}
				}
				&.small-slide {
					padding: 0 60px;
					.item-info {
						width: 100%;
						padding: 0;
						left: 0;
						top: 0;
						h6 {
							margin-bottom: 24px;
							color: $primary;
							&:first-child {
								display: none;
							}
							&:last-child {
								display: block;
							}
						}
						ul {
							margin: 0 0 32px 0;
							li {
								.icon-wrap {
									img:first-child {
										display: none;
									}
									img:last-child {
										display: inline;
									}
								}
								span {
									color: #5D5864;
								}
							}
						}
					}
					.img-wrap {
						height: 60%;
						&:before {
							opacity: 0;
						}
					}
				}
			}
		}
		&:not(.glide--swipeable) {
			.glide__slide {
				width: 38.46%;
				transition: all 0s ease-in-out;
				.item {
					.item-info,
					.img-wrap {
						transition: all 0s ease-in-out;
					}
					.img-wrap {
						position: relative;
					}
				}
				&.small-slide {
					.item {
						.img-wrap {
							top: 40%;
							position: absolute;
						}
					}
				}
				&:nth-child(2n+2) {
					padding: 0 60px;
					.img-wrap {
						max-width: 100%;
						max-height: 60%;
					}
				}
			}
		}
	}
}

#home-academy {
	max-width: 100%;
	overflow: hidden;
	.row > div {
		max-width: 100%;
	}
	#mobile-head {
		padding-bottom: 20px;
	}
	#academy-slider {
		max-width: 100%;
		.glide__slides {
			padding-bottom: 60px;
		}
		.glide__slide {
			position: relative;
			.item {
				.img-wrap {
					min-width: 65%;
					overflow: hidden;
					position: relative;
					picture, img {
						width: 100%;
						max-width: 100%;
					}
				}
				.item-info {
					min-height: 80%;
					padding: 0 0 0 48px;
				}
				h3 {
					padding-right: 80px;
					margin-bottom: 38px;
					color: $primary;
				}
				p {
					line-height: 20px;
					margin-bottom: 24px;
					font-size: 14px;
					color: #5D5864;
				}
			}
		}
		.buttons {
			padding-top: 50%;
			.glide__arrow--left {
				margin-right: 30px;
			}
			.glide__arrow--disabled {
				opacity: .45;
				pointer-events: none;

			}
		}
		.glide__arrows {
			padding-top: 28px;
			a.glide__arrow--left {
				margin-right: 16px;
			}
		}
		&:not(.glide--swipeable) {
			.glide__slide {
				width: 70%;
			}
			.buttons {
				display: none !important;
			}
		}
	}
}

#home-testimonials {
	padding: 160px 0 220px 0;
	position: relative;
	overflow: hidden;
	.col-12 {
		&:last-child {
			position: relative;
			&:after {
				width: 50vw;
				height: 100%;
				content: "";
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				z-index: 20;
				background: $white;
			}
		}
	}
	.desc-wrap {
		position: relative;
		z-index: 30;
		.desc {
			opacity: 0;
			transition: all .3s ease-in-out;
			p {
				line-height: 24px;
				margin-bottom: 26px;
				font-size: 1rem;
				color: #5D5864;
			}
			span {
				line-height: 20px;
				font-size: 14px;
				color: #5D5864;
				&.name {
					line-height: 24px;
					margin-bottom: 8px;
					font-size: 18px;
					font-weight: 500;
				}
			}
			&.active {
				opacity: 1;
			}
		}
		.buttons {
			padding: 0 28px 48px 0;
			.glide__arrow--left {
				margin-right: 30px;
			}
		}
	}
	.glide__track,
	.glide__slides {
		overflow: visible;
	}
	.glide__slide {
		.item {
			.img-wrapper {
				position: relative;
				.animate {
					&.b-r {
						bottom: 160px;
					}
					&.t-l {
						left: -80px;
						top: -80px;
					}
					&.b-l {
						left: auto;
						right: 0;
					}
				}
			}
			img {
				max-width: 100%;
			}
		}
	}
}

#home-fellowship {
	.fellowship-head {
		height: 160px;
		background: #DC0005;
		color: $white;
		position: relative;
		h6 {
			line-height: 42px;
			margin-bottom: 14px;
			font-size: 36px;
			font-weight: 500;
		}
		p {
			line-height: 24px;
			margin-bottom: 0;
			font-size: 16px;
		}
	}
	#fellowship-slider {
		.glide__slide {
			position: relative;
			a {
				width: 100%;
				img {
					width: 100%;
				}
			}
			&:after {
				width: 100%;
				height: 75%;
				content: "";
				display: block;
				position: absolute;
				bottom: 0;
				left: 0;
			    background:-moz-linear-gradient(180deg, #00000000 0%, #000000AD 100%); 
			    background:-webkit-linear-gradient(180deg, #00000000 0%, #000000AD 100%);
			    background:-o-linear-gradient(180deg, #00000000 0%, #000000AD 100%);
			    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );
			    background:-ms-linear-gradient(180deg, #00000000 0%, #000000AD 100%);
			    background:linear-gradient(180deg, #00000000 0%, #000000AD 100%);
			}
		}
		.arrow-container {
			position: absolute;
			left: 0;
			bottom: 32px;
			a.glide__arrow--left {
				margin-right: 30px;
			}
		}
		&:not(.glide--swipeable) {
			.glide__arrows {
				display: none !important;
			}
		}
	}
}

#home-blog {
	padding: 84px 0 80px 0;
	overflow: hidden;
	.head-row {
		margin-bottom: 52px;
	}
	#blog-slider {
		.glide__track,
		.glide__slides {
			overflow: visible;
		}
		@import "../../base/modules/_blog-item";
		.blog-item {
			h4 {
				max-width: 100%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		&:not(.glide--swipeable) {
			.glide__slide {
				width: calc(50% - 15px);
				margin-right: 30px;
			}
		}
	}
}

#partners {
	height: 160px;
	margin-bottom: 120px;
	background: $primary;
	.partner-wrapper {
		padding-left: 220px;
		position: relative;
		h6 {
			line-height: 24px;
			max-width: 120px;
			margin: 0;
			font-size: 18px;
			color: $white;
		}
		#partner-slider {
			max-width: 70%;
			margin: 0 30px;
			.glide__slides {
				align-items: center;
			}
			.glide__slide {
				width: 33%;
				text-align: center;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				a {
					max-width: 75%;
					display: inline-flex;
					justify-content: center;
					align-items: center;
					img {
						max-width: 100%;
					}
				}
			}
		}
	}
}


@import "./media";