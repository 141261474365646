$animateWidth: 60px;
$duration: .4s;
$function: ease-out;

[data-animate="fade-in"] {
	position: relative;
	opacity: 0;
	transition-property: top, left, bottom, right, opacity;
	transition-duration: $duration;
	transition-timing-function: $function;

	&[data-animate-way="bottom-top"] {
		top: $animateWidth;
	}
	&[data-animate-way="top-bottom"] {
		bottom: $animateWidth;
	}
	&[data-animate-way="left-right"] {
		right: $animateWidth;
	}
	&[data-animate-way="right-left"] {
		left: $animateWidth;
	}

	&[data-animated="true"] {
		&[data-animate-way="bottom-top"] {
			top: 0;
			opacity: 1;
		}
		&[data-animate-way="top-bottom"] {
			bottom: 0;
			opacity: 1;
		}
		&[data-animate-way="left-right"] {
			right: 0;
			opacity: 1;
		}
		&[data-animate-way="right-left"] {
			left: 0;
			opacity: 1;
		}
	}
}

[data-animate-delay="1"] {
	transition-delay: .1s;
}
[data-animate-delay="2"] {
	transition-delay: .2s;
}
[data-animate-delay="3"] {
	transition-delay: .3s;
}
[data-animate-delay="4"] {
	transition-delay: .4s;
}
[data-animate-delay="5"] {
	transition-delay: .5s;
}
[data-animate-delay="6"] {
	transition-delay: .6s;
}
[data-animate-delay="7"] {
	transition-delay: .7s;
}
[data-animate-delay="8"] {
	transition-delay: .8s;
}
[data-animate-delay="9"] {
	transition-delay: .9s;
}
[data-animate-delay="10"] {
	transition-delay: 1s;
}
[data-animate-delay="11"] {
	transition-delay: 1.1s;
}
[data-animate-delay="12"] {
	transition-delay: 1.2s;
}
[data-animate-delay="13"] {
	transition-delay: 1.3s;
}
[data-animate-delay="14"] {
	transition-delay: 1.4s;
}
[data-animate-delay="15"] {
	transition-delay: 1.5s;
}
[data-animate-delay="16"] {
	transition-delay: 1.6s;
}
[data-animate-delay="17"] {
	transition-delay: 1.7s;
}
[data-animate-delay="18"] {
	transition-delay: 1.8s;
}
[data-animate-delay="19"] {
	transition-delay: 1.9s;
}
[data-animate-delay="20"] {
	transition-delay: 2s;
}